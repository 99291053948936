<template>
  <div>
    <cbs-objects-tbl meta-filter="import" title="IMPORTS" />
  </div>
</template>

<script>
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsObjectsImport',
  components: {
    CbsObjectsTbl,
  },
  created() {
    useCubus.setPageTitle('Imports')
  },
}
</script>

<style scoped>

</style>
